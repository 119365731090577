import React, {
    Component
}
    from 'react';
import {
    HashRouter,
    Route,
    Switch
}
    from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.css';


const loading = () =>  < div className = "animated fadeIn pt-3 text-center" > Loading... <  / div > ;

    // Containers
    const Search = React.lazy(() => import('./views/Search/Search.js'));
    const Accounts = React.lazy(() => import('./views/Accounts/Accounts.js'));



    class App extends Component {
        render() {
        return (

        < HashRouter >
        < React.Suspense fallback = {loading()} >
        < Switch >
        <Route exact path = "/" name = "Search Page" component = {Search} />
        <Route exact path = "/accounts" name = "Accounts Page" component = {Accounts} />
        </Switch>
        </React.Suspense>
        </HashRouter>

        );
    }
    }


    export default App;
